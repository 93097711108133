<template>
  <div ref="chart" style="height: 100%; width: 100%; float:left;"></div>
</template>
<script>
export default {
  name: "Waterfall",
  data() {
    return {
      chart: null,
      option: {
        title: {
          text: "Users From Each Customer",
          subtext: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function(params) {
            var tar = params[1];
            return tar.name + "<br/>" + tar.seriesName + " : " + tar.value;
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          splitLine: { show: false },
          data: [
            "Total",
            "Internal",
            "Geely",
            "Hirain",
            "NavInfo",
            "Bosch Huayu",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Placeholder",
            type: "bar",
            stack: "Total",
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent",
              },
            },
            data: [0, 1700, 1400, 1200, 300, 0],
          },
          {
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "inside",
            },
            data: [2900, 1200, 300, 200, 900, 300],
          },
        ],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.chart = this.$Echarts.init(this.$refs.chart);
      this.chart.setOption(this.option);
    },
  },
};
</script>
<style scoped></style>
