<template>
  <div ref="chart" style="height: 100%; width: 100%; float:left;"></div>
</template>
<script>
export default {
  name: "PieChart",
  data() {
    return {
      chart: null,
      option: {
        title: {
          text: "Requests by Customers",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [
              { value: 1048, name: "NavInfo" },
              { value: 735, name: "Bosch Huayu" },
              { value: 580, name: "Eberspaecher" },
              { value: 484, name: "Geely" },
              { value: 300, name: "Hirain" },
              { value: 484, name: "UAES" },
              { value: 300, name: "Internal" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.chart = this.$Echarts.init(this.$refs.chart, "vintage");
      this.chart.setOption(this.option);
    },
  },
};
</script>
<style scoped></style>
