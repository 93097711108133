<template>
  <div ref="chart" style="height: 100%; width: 100%; float:left;"></div>
</template>
<script>
export default {
  name: "BarChart",
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log(this.$Echarts);
      //2.初始化
      this.chart = this.$Echarts.init(this.$refs.chart, "vintage");
      //3.配置数据
      let option = {
        title: {
          text: "Requests Created in Last 7 Days",
        },
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        }, //X轴
        yAxis: { type: "value" }, //Y轴
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: "bar",
          },
        ], //配置项
      };
      // 4.传入数据
      this.chart.setOption(option);
    },
  },
};
</script>
<style scoped></style>
