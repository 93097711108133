<template>
  <div style="text-align: center;">
    <bar-chart
      style="float:left; width: 400px; height: 400px; margin-top: 40px;"
    ></bar-chart>
    <pie-chart
      style="float:left; width: 500px; height: 400px; margin-left: 40px; margin-top: 40px;"
    ></pie-chart>

    <water-fall
      style="float:left; width: 400px; height: 400px; margin-left: 40px; margin-top: 40px;"
    ></water-fall>
    <line-chart
      style="float:left; width: 400px; height: 400px; margin-left: 40px; margin-top: 40px;"
    ></line-chart>
  </div>
</template>
<script>
import BarChart from "./BarChart.vue";
import PieChart from "./PieChart.vue";
import LineChart from "./LineChart.vue";
import WaterFall from "./Waterfall.vue";
export default {
  name: "Report",
  components: {
    BarChart,
    PieChart,
    LineChart,
    WaterFall,
  },
  data() {
    return {
      height: window.innerHeight - 180,
    };
  },
};
</script>
<style scoped>
.chart {
  float: left;
  height: 400px;
  width: 400px;
}
</style>
